import { inject }                     from 'aurelia-framework';
import { TracksRepository }           from 'modules/aggregates/lab/services/tracks-repository';
import { OEDatasRepository }          from 'modules/management/concessions/concessions-tree/lot-constructions/oe-data/services/repository';
import { LotInterventionsRepository } from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { LotConstructionType }        from 'modules/management/models/lot-construction-type';

@inject(LotInterventionsRepository, OEDatasRepository, TracksRepository)
export class ConstructionSchema {

    schemaSlug = 'construction_fields';

    /**
     *
     * @param tracksRepository
     * @param lotInterventionsRepository
     * @param oeDatasRepository
     */
    constructor(lotInterventionsRepository, oeDatasRepository, tracksRepository = null) {
        this.lotInterventionsRepository = lotInterventionsRepository;
        this.oeDatasRepository          = oeDatasRepository;
        this.tracksRepository           = tracksRepository;
    }

    /**
     * Returns default schema for Construction fields
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    async schema(viewModel, readonly = false) {

        const branchLabel        = 'form.field.branch';
        const edgeLabel          = 'form.field.edge';
        const pkLabel            = 'form.field.pk';
        const notApplicableLabel = 'form.text.not-applicable';

        const filterData = async (newValue) => {
            return await this.oeDatasRepository.filterData(newValue);
        };

        /**
         * Filter Lot Construction predominant attributes
         * from OELotConstructionData
         *
         * @param lotConstructionId
         * @returns {Promise<void>}
         */
        const filterConstructionPredominant = async (lotConstructionId) => {

            // Reset Edge
            this.edge_id.label = edgeLabel;
            this.edge_id.instance.hide(false);
            this.edge_id.instance.clear(!lotConstructionId);

            // Reset Branch
            this.branch_id.label = branchLabel;
            this.branch_id.instance.hide(false);
            this.branch_id.instance.clear(!lotConstructionId);

            // Reset PK
            this.oe_data_id.label = pkLabel;
            this.oe_data_id.instance.hide(false);
            this.oe_data_id.instance.clear(!lotConstructionId);

            // Early bird gets the worm :)
            if (!lotConstructionId) return;

            // Enable all fields
            this.branch_id.instance.enable();
            this.edge_id.instance.enable();
            this.oe_data_id.instance.enable();

            // Reset model values
            await fetchOEDataFromId();

            // Get filtered data and predominant
            const { predominant, options } = await filterData(lotConstructionId);

            // Update form behaviour based on predominant attribute
            switch (predominant) {
                /* Branch is predominant */
                case 'branch':
                    // Set Branch options
                    this.branch_id.options = options;

                    // Ignore Edge
                    this.edge_id.label = notApplicableLabel;
                    this.edge_id.instance.hide();

                    // Disable PK
                    this.oe_data_id.instance.disable();
                    break;

                /* Edge is predominant */
                case 'edge':
                    // Set Edge options
                    this.edge_id.options = options;

                    // Ignore Branch
                    this.branch_id.label = notApplicableLabel;
                    this.branch_id.instance.hide();

                    // Disable PK
                    this.oe_data_id.instance.disable();
                    break;

                /* PK is predominant (options are always set) */
                case 'pk':
                    // Set PK options
                    this.oe_data_id.options = options;

                    // Ignore Branch
                    this.branch_id.label = notApplicableLabel;
                    this.branch_id.instance.hide();

                    // Ignore Edge
                    this.edge_id.label = notApplicableLabel;
                    this.edge_id.instance.hide();

                    break;

                /* No predominance, meaning no valid OE data is available. */
                default:

                    // Ignore Branch
                    this.branch_id.label = notApplicableLabel;
                    this.branch_id.instance.disable();

                    // Ignore Edge
                    this.edge_id.label = notApplicableLabel;
                    this.edge_id.instance.disable();

                    // Ignore PK
                    this.oe_data_id.label = notApplicableLabel;
                    this.oe_data_id.instance.disable();

                    break;
            }

        };

        /**
         * Fetch and update Construction readonly fields
         *
         * @param oeDataId
         * @returns void
         */
        const fetchOEDataFromId = (oeDataId = null) => {
            // Early bird gets the worm.
            if (!oeDataId) {
                viewModel.model.number_of_tracks = null;
                viewModel.model.axis             = null;
                return;
            }

            // Find OELotConstructionData and assign values
            this.oeDatasRepository.find(oeDataId).then(response => {
                viewModel.model.number_of_tracks = response.number_of_lanes;
                viewModel.model.axis             = response.axis;
            });
        };

        /**
         * Update Data
         *
         * @param criteria
         * @returns {Promise<void>}
         */
        const updateOELotConstructionData = async (criteria = null) => {
            // Early bird gets the worm.
            if (!criteria) {
                viewModel.model.o_e_lot_construction_data_id = null;
                fetchOEDataFromId(); // clear readonly fields
                return;
            }

            // Search PK values based on given criteria
            let oeData = await this.oeDatasRepository.searchPKValues(criteria);

            // if it has
            if (!oeData || !Array.isArray(oeData)) {
                viewModel.model.o_e_lot_construction_data_id = null;
                fetchOEDataFromId(); // clear readonly fields
                return;
            }

            let pk = this.oe_data_id;

            // Set options
            pk.options = oeData;
            // Set options
            pk.instance.enable();

            // If there's only one pk value, disable it
            if (oeData.length === 1) {
                viewModel.model.o_e_lot_construction_data_id = oeData[0].id;
                fetchOEDataFromId(oeData[0].id);
                pk.instance.disable();
            } else {
                fetchOEDataFromId(); // clear readonly fields
            }

        };

        // If lot_construction_id is set (user is editing), fetch construction data.
        let constructionResponse = false;
        let pkValues             = [];

        if (viewModel.model.lot_construction_id) {
            constructionResponse = await filterData(viewModel.model.lot_construction_id);
        }

        const { predominant, options } = constructionResponse || {};

        this.lot_construction_id = {
            type:         'select2',
            key:          'lot_construction_id',
            idPrefix:     this.schemaSlug,
            label:        'form.field.local',
            remoteSource: () => this.lotInterventionsRepository.allConstructions(viewModel.model.lot_intervention_id, LotConstructionType.OE),
            size:         6,
            required:     false,
            attributes:   {
                disabled: readonly,
            },
            observers:    [
                (newValue) => {
                    filterConstructionPredominant(newValue);
                },
            ],
        };

        this.branch_id = {
            type:         'select2',
            key:          'branch_id',
            idPrefix:     this.schemaSlug,
            label:        constructionResponse && predominant !== 'branch' ? notApplicableLabel : branchLabel,
            remoteSource: () => Promise.resolve(
                (constructionResponse && predominant === 'branch')
                    ? options
                    : [],
            ),
            size:         6,
            required:     false,
            attributes:   {
                disabled: !(constructionResponse && predominant === 'branch') || readonly,
            },
            observers:    [
                (newValue) => {
                    if (newValue) {
                        updateOELotConstructionData({ branch_id: newValue });
                    }
                },
            ],
        };

        this.edge_id = {
            type:         'select2',
            key:          'edge_id',
            idPrefix:     this.schemaSlug,
            label:        constructionResponse && predominant !== 'edge' ? notApplicableLabel : edgeLabel,
            remoteSource: () => Promise.resolve(
                (constructionResponse && predominant === 'edge')
                    ? options
                    : [],
            ),
            size:         6,
            required:     false,
            attributes:   {
                disabled: !(constructionResponse && predominant === 'edge') || readonly,
            },
            observers:    [
                (newValue) => {
                    if (newValue) {
                        updateOELotConstructionData({ edge_id: newValue });
                    }
                },
            ],
        };

        // If there's a response (editing), fetch PK values
        if (constructionResponse) {
            pkValues = await this.oeDatasRepository.searchPKValues({ [predominant + '_id']: viewModel.model[predominant + '_id'] });
        }

        this.collection_pk = {
            type:     'field-group',
            label:    'form.field.collection-pk',
            key:      'collection_pk',
            required: false,
            size:     6,
            fields:   [
                {
                    type:         'select2',
                    idPrefix:     this.schemaSlug,
                    key:          'o_e_lot_construction_data_id',
                    label:        'form.field.pk',
                    remoteSource: () => Promise.resolve(pkValues),
                    observers:    [
                        (newValue) => {
                            if (newValue) {
                                fetchOEDataFromId(newValue);
                            }
                        },
                    ],
                    required:     false,
                    size:         6,
                    attributes:   {
                        disabled: !(constructionResponse && predominant === 'pk' && pkValues.length > 1) || readonly,
                    },
                },
                {
                    type:            'text',
                    key:             'pk_s',
                    idPrefix:        this.schemaSlug,
                    showLabel:       false,
                    required:        false,
                    size:            2,
                    observers:       [
                        (newValue) => newValue.length === 3 && this.focusField('pk_f', true),
                    ],
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                        readonly:  readonly,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '30p',
                },
                {
                    type:            'text',
                    key:             'pk_f',
                    idPrefix:        this.schemaSlug,
                    showLabel:       false,
                    required:        false,
                    size:            2,
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                        readonly:  readonly,
                    },
                },
            ],
        };

        // simplify oe_data_id reference
        this.oe_data_id = this.collection_pk.fields[0];

        this.applied_pk = {
            type:     'field-group',
            label:    'form.field.material-applied-pk(from-to)',
            key:      'applied_pk',
            required: false,
            size:     6,
            fields:   [
                {
                    type:            'text',
                    key:             'pks_s',
                    idPrefix:        this.schemaSlug,
                    showLabel:       false,
                    required:        false,
                    size:            2,
                    observers:       [
                        (newValue) => newValue.length === 3 && this.focusField('pks_f'),
                    ],
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                        readonly:  readonly,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '30p',
                },
                {
                    type:            'text',
                    key:             'pks_f',
                    idPrefix:        this.schemaSlug,
                    showLabel:       false,
                    required:        false,
                    size:            2,
                    observers:       [
                        (newValue) => newValue.length === 6 && this.focusField('pkf_s'),
                    ],
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                        readonly:  readonly,
                    },
                },
                {
                    type:       'divider',
                    label:      'a',
                    size:       '30p',
                    attributes: {
                        style: 'font-size: 1rem; line-height: 4.825; display: inline-block;',
                    },
                },
                {
                    type:            'text',
                    key:             'pkf_s',
                    idPrefix:        this.schemaSlug,
                    showLabel:       false,
                    required:        false,
                    size:            2,
                    observers:       [
                        (newValue) => newValue.length === 3 && this.focusField('pkf_f'),
                    ],
                    valueConverters: [
                        { name: 'numbersOnly' },
                    ],
                    attributes:      {
                        maxlength: 3,
                        readonly:  readonly,
                    },
                },
                {
                    type:  'divider',
                    label: '+',
                    size:  '30p',
                },
                {
                    type:            'text',
                    key:             'pkf_f',
                    idPrefix:        this.schemaSlug,
                    showLabel:       false,
                    required:        false,
                    size:            2,
                    valueConverters: [
                        { name: 'autoDecimalValue' },
                    ],
                    attributes:      {
                        maxlength: 6,
                        readonly:  readonly,
                    },
                },
            ],
        };

        this.number_of_tracks = {
            type:       'text',
            key:        'number_of_tracks',
            idPrefix:   this.schemaSlug,
            label:      'form.field.number-of-tracks',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        this.axis = {
            type:       'text',
            key:        'axis',
            idPrefix:   this.schemaSlug,
            label:      'form.field.axis',
            size:       6,
            required:   false,
            attributes: {
                readonly: true,
            },
        };

        if (this.tracksRepository !== null) {
            this.track_id = {
                type:         'select2',
                key:          'track_id',
                idPrefix:     this.schemaSlug,
                label:        'form.field.track-side',
                remoteSource: () => this.tracksRepository.all(),
                size:         6,
                required:     false,
                attributes:   {
                    disabled: readonly,
                },
            };
        }
    }

    /**
     * Focus on a different field from current schema
     * @param fieldKey
     */
    focusField(fieldKey) {
        document.querySelector(`#${this.schemaSlug}__${fieldKey}`)?.focus();
    }
}
