import { inject }           from 'aurelia-framework';
import { BaseRepository }   from 'modules/core/services/base-repository';
import { CustomHttpClient } from 'resources/services/custom-http-client';

@inject(CustomHttpClient)
export class AggregatesLabTestsRepository extends BaseRepository {
    baseUrl         = 'aggregates/lab-tests';
    originalBaseUrl = 'aggregates/lab-tests/';

    /**
     * Set category of AggregatesLabTest
     * @param category
     */
    setCategory(category) {
        this.baseUrl = this.originalBaseUrl + category;
    }

    /**
     * Fetches the list of records validated for the current lot intervention
     *
     * @return {*}
     */
    validatedByCurrentLotIntervention() {
        return this.httpClient.get(`${this.baseUrl}/validated-by-current-lot-intervention`);
    }

    /**
     * Fetches the list of records processed for the current lot intervention
     *
     * @return {*}
     */
    processedByCurrentLotIntervention() {
        return this.httpClient.get(`${this.baseUrl}/processed-by-current-lot-intervention`);
    }

    /**
     * Fetches the next (suggested) number
     *
     * @returns {*}
     */
    nextNumber() {
        return this.httpClient.get(`${this.baseUrl}/next-number`);
    }

    /**
     * Generates a zip with records' bulletin files
     *
     * @returns {*}
     */
    generateZip(criteria) {
        return this.httpClient.post(`${this.baseUrl}/generate-zip`, criteria);
    }

    /**
     * Returns reference tests samples
     *
     * @returns {*}
     */
    referenceSamples(currentId) {
        return this.httpClient.post(`${this.baseUrl}/reference-samples`, { current_id: currentId });
    }

    /**
     * Validate a given record
     *
     * @param id
     * @return {*}
     */
    validate(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/validate`);
    }

    /**
     * Invalidate a given record
     *
     * @param id
     * @return {*}
     */
    invalidate(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/invalidate`);
    }

    /**
     * Verify a given record
     *
     * @param id
     * @return {*}
     */
    verify(id) {
        return this.httpClient.patch(`${this.baseUrl}/${id}/verify`);
    }

    /**
     * Validate selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    validateSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/validate-selected`, { selectedRows });
    }

    /**
     * Invalidate selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    invalidateSelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/invalidate-selected`, { selectedRows });
    }

    /**
     * Verify selected records
     *
     * @param selectedRows
     *
     * @return {*}
     */
    verifySelected(selectedRows) {
        return this.httpClient.patch(`${this.baseUrl}/verify-selected`, { selectedRows });
    }

    /**
     * Performs a search given some criteria, returns datatable
     *
     * @param sectorId
     * @param criteria
     *
     * @returns {*}
     */
    search(sectorId, criteria) {
        return this.httpClient.post(`${this.baseUrl}/search`, { sector_id: sectorId, ...criteria });
    }

    /**
     * Creates a record
     *
     * @param sectorId
     * @param data
     *
     * @returns {*}
     */
    create(sectorId, data) {
        return this.httpClient.post(`${this.baseUrl}`, { sector_id: sectorId, ...data });
    }

    /**
     * Updates a record
     *
     * @param sectorId
     * @param id
     * @param data
     *
     * @returns {*}
     */
    update(sectorId, id, data) {
        return this.httpClient.put(`${this.baseUrl}/${id}`, { sectorId, ...data });
    }

    /**
     * Gets the lab with ttmc by current lot intervention
     *
     * @return {*}
     */
    getTTMCbyCurrentLotIntervention() {
        return this.httpClient.get('aggregates/lab-tests/ttmc-by-current-lot-intervention');
    }
}
