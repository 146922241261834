import { DialogService }                    from 'aurelia-dialog';
import { inject }                           from 'aurelia-framework';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';
import { ViewModalAdditionalInformation }   from 'modules/management/additional-information/additional-informations/view/index-modal';

@inject(AdditionalInformationsRepository, DialogService)
export class AdditionalInfoSchema {

    schemaSlug = 'additional_info';

    constructor(additionalInfoRepository, dialogService) {
        this.additionalInfoRepository = additionalInfoRepository;
        this.dialogService            = dialogService;
    }

    /**
     * Returns form schema for "Additional Information" tab
     *
     * @param viewModel
     * @param readonly
     *
     * @returns {*[]}
     */
    schema(viewModel, readonly = false) {

        this.additional_info_ids = {
            type:              'duallistbox',
            key:               'additional_info_ids',
            label:             'form.field.additional-information',
            remoteSource:      () => this.additionalInfoRepository.searchMap(),
            handleDoubleClick: (selectedOption) => {
                if (selectedOption.id) {
                    this.dialogService
                        .open({
                            viewModel: ViewModalAdditionalInformation,
                            model:     { id: selectedOption.id },
                        });
                }
            },
            required:          false,
            size:              12,
            attributes:        {
                disabled: readonly,
            },
        };

        return [
            [this.additional_info_ids],
        ];
    }

}
